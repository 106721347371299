import React, { useEffect, useState } from 'react';
import { useDispatch,  useSelector } from 'react-redux';
import {useHistory} from 'react-router-dom';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import Avatar  from '@mui/material/Avatar';
import 'react-alice-carousel/lib/alice-carousel.css';

import TwitterIcon from '@mui/icons-material/Twitter';

import {page} from '../../styles/main';
import { List, requirePropFactory } from '@mui/material';

import bird_data from '../../data/summary/BIRD_SUMMARY.json';


import { getData } from '../../actions/instrument';


const classes = {
    root:{
        marginTop:10
    }
}

const styles = {
    media: {
      height: '100%',
      paddingTop: '75%', // 16:9,
      marginTop:'30'
    },
};






export default function Main(){
    const history = useHistory();
    const dispatch = useDispatch();
    

    return(
      <div>
      <Grid style={{height:"100vh"}} justifyContent="center" alignItems="center" container direction="row">
        <Grid item xs={12} lg={8} sm={12} md={12} style={{marginTop:0, padding:10,}}>
           
                <Typography variant="h4"><b>THERMPA</b></Typography>
               
                <div style={{marginTop:50}}>
                <Grid container direction="row" spacing={1}>
                    {bird_data.map((item) => (
                         <Grid item md={3} lg={3} sm={3}  xs={12}>
                         <Display data={item}   />
                        </Grid>
                    ))}
                </Grid>
                </div>
                

                
        </Grid>
      </Grid>
           
        </div>
    )
}
function Display(props){
    const history = useHistory();
    const [data, setData] = useState(props.data ? props.data : [])

    useEffect(() => {
      setData(props.data ? props.data: []);
    }, [props.data])

    function handleClick(){
        history.push("/bird/" + props.data.code.toLowerCase());
    }


    return(
       
          <ListItem button onClick={handleClick}>
            <div style={{flex:1, flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
            <Grid container direction="row" justifyContent="center">
                <Avatar src={require('../../images/birds/' + props.data.code + '.jpg')} style={{width:60, height:60}} >
                  <TwitterIcon />
                </Avatar>
                    
                </Grid>
               
                <Typography align="center" variant="body1"> 
                    {props.data.name} 
                </Typography>
            </div>
                
                
           
          </ListItem>
    )
}