import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import Slider from '@mui/material/Slider';
import ListItem from '@mui/material/ListItem';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import Chip from '@mui/material/Chip';


import bird_data from '../../data/summary/BIRD_SUMMARY.json';
import { useParams, useHistory } from 'react-router-dom';
import { IconButton, requirePropFactory, Tooltip, Typography } from '@mui/material';
import { getPerformance } from '../../functions/performance';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';


function LinearProgressWithLabel(props) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value,
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }
  
  LinearProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
  };
  

export default function Main(){

    const history = useHistory()
    let { code } = useParams();
    const [summary, setSummary] = useState(bird_data ? bird_data.filter((item) => item.code === code.toUpperCase()): {});
    const [results, setResults] = useState({});
    const [svrModel, setSvrModel] = useState([]);
    const [gprModel, setGprModel] = useState([]);
    const [annModel, setAnnModel] = useState([]);
    const [status, setStatus] = useState("Loading...");
    const [progress, setProgress] = React.useState(0);
    const [ensembleModel, setEnsembleModel] = useState([]);
    const [predictors, setPredictors] = useState({mass: summary[0].min_mass, atemp: 20});

    useEffect(() => {
        loadSVRModels();
        loadGPRModels();
        loadANNModels();
        loadEnsembleModels();
    }, [])

    async function loadSVRModels(){
        setStatus("Loading Models")
        const response = await fetch(process.env.PUBLIC_URL + "/csv/" + code.toUpperCase() + '_SVR_MODEL.csv');
        const data = await response.text();
        const rows = data.split('\n').slice(1);
        setSvrModel(rows);
        setProgress((prevProgress) => (prevProgress <= 100 ? 100 : prevProgress + 25));
    }

    async function loadGPRModels(){
        setStatus("Loading Models")
        const response = await fetch(process.env.PUBLIC_URL + "/csv/" + code.toUpperCase() + '_GPR_MODEL.csv');
        const data = await response.text();
        const rows = data.split('\n').slice(1);
        setGprModel(rows);
        setProgress((prevProgress) => (prevProgress <= 100 ? 100 : prevProgress + 25));
    }

    async function loadANNModels(){
        setStatus("Loading Models")
        const response = await fetch(process.env.PUBLIC_URL + "/csv/" + code.toUpperCase() + '_ANN_MODEL.csv');
        const data = await response.text();
        const rows = data.split('\n').slice(1);
        setAnnModel(rows);
        setProgress((prevProgress) => (prevProgress <= 100 ? 100 : prevProgress + 25));
    }

    async function loadEnsembleModels(){
        setStatus("Loading Models")
        const response = await fetch(process.env.PUBLIC_URL + "/csv/" + code.toUpperCase() + '_ENSEMBLE_MODEL.csv');
        const data = await response.text();
        const rows = data.split('\n').slice(1);
        setEnsembleModel(rows);
        setProgress((prevProgress) => (prevProgress <= 100 ? 100 : prevProgress + 25));
        setStatus("Models Loaded Successfully")
    }

    useEffect(() => {
        setSummary(bird_data ? bird_data.filter((item) => item.code === code.toUpperCase()): {});
    }, [code])

    function getPredictions(){
        svrPredict();
        gprPredict();
        annPredict();
        ensemblePredict();
    }

    function svrPredict(){
        const rows = svrModel;
        for(var k = 0; k < rows.length; k++){
            const columns = rows[k].split(',')
            if(columns[0] == predictors.mass && columns[1] == predictors.atemp ){
                var pred = {tb: columns[2], ewl: columns[3], rmr: columns[4]}
                setResults((res) => ({...res, svr: pred}));
                break;
            }
        }
    }

    function gprPredict(){
        const rows = gprModel;
        for(var k = 0; k < rows.length; k++){
            const columns = rows[k].split(',')
            if(columns[0] == predictors.mass && columns[1] == predictors.atemp ){
                var pred = {tb: columns[2], ewl: columns[3], rmr: columns[4]}
                setResults((res) => ({...res, gpr: pred}));
                break;
            }
        }
    }

    function annPredict(){
        const rows = annModel;
        for(var k = 0; k < rows.length; k++){
            const columns = rows[k].split(',')
            if(columns[0] == predictors.mass && columns[1] == predictors.atemp ){
                var pred = {tb: columns[2], ewl: columns[3], rmr: columns[4]}
                setResults((res) => ({...res, ann: pred}));
                break;
            }
        }
    }

    function ensemblePredict(){
        const rows = ensembleModel;
        for(var k = 0; k < rows.length; k++){
            const columns = rows[k].split(',')
            if(columns[0] == predictors.mass && columns[1] == predictors.atemp ){
                var pred = {tb: columns[2], ewl: columns[3], rmr: columns[4]}
                setResults((res) => ({...res, ensemble: pred}));
                break;
            }
        }
    }

    function handleChange(predictor, value){
        setPredictors(pred => ({...pred, [predictor]: value}));
        getPredictions();
    }

   
    
    return(
    
        <div>
        <Grid style={{height:"100vh"}} justifyContent="center"  container direction="row">
        <Grid item xs={12} lg={10} sm={12} md={12} style={{marginTop:0, padding:10}}>


        <div style={{marginTop:20}}>
            <Grid container direction="row">
                <IconButton onClick={() => history.goBack()} size="large" color="primary" >
                    <ChevronLeftIcon fontSize='large' />
                </IconButton>
            </Grid>
        </div>
        
        <div style={{marginTop:50}}>
        <Grid container direction="row" alignItems="center" spacing={1}>
            <Grid item>
            <Avatar style={{width:100, height:100}} src={require("../../images/birds/" + code.toUpperCase() + '.jpg')} />
            </Grid>
            <Grid item md={9}>
                <Typography variant="h4"> <b>{summary[0].name}</b> </Typography>
                <div>

                </div>
            </Grid>
        </Grid>
      
        </div>

       

        <div style={{marginTop:50}}>
            <Grid container direction ="row" spacing={2} justifyContent="space-between">
                <Grid item md={12} lg={5} sm={12} xs={12}>

                    <div style={{marginTop:20}}>
                    <MassSlider min={summary[0].min_mass} 
                                max={summary[0].max_mass} 
                                enable={progress === 100 ? true : false }
                                onChange={(value) => handleChange("mass", value)} />
                    </div>

                    <div style={{marginTop:20}}>
                        <TempSlider 
                         enable={progress === 100 ? true : false }
                        onChange={(value) => handleChange("atemp", value)} />
                    </div>
   
                </Grid>
                <Grid item md={12} lg={6} sm={12} xs={12}>
                    <div style={{marginBottom:20}}>
                        <Typography variant="h6" color="textSecondary"> {status} </Typography>
                        <LinearProgressWithLabel value={progress} />
                    </div>
                    
                    <Grid container direction="row" alignItems="center">
                        <Grid item md={3} sm={3} xs={3}>
                        <ListItem style={{borderColor:"#000000", borderStyle:"solid", 
                            borderWidth:1, alignItems:"center", justifyContent:"center"}}>
                            <Typography align="center" variant="body1">MODELS</Typography>
                        </ListItem>
                        </Grid>
                        <Grid item md={3} sm={3} xs={3}>
                            <ListItem style={{borderColor:"#000000", flexDirection:"column", borderStyle:"solid", borderWidth:1, 
                            alignItems:"center", justifyContent:"center"}}>
                                <div style={{flexDirection:"row", flex:1, alignItems:"center", justifyContent:"center"}}>
                                <Typography align="center" variant="body1">Tb <small style={{fontSize:10}}>&#xb0;C</small> </Typography>
                                </div>
                            </ListItem>
                        </Grid>
                        <Grid item md={3} sm={3} xs={3}>
                            <ListItem style={{borderColor:"#000000", borderStyle:"solid", borderWidth:1,
                             alignItems:"center", justifyContent:"center"}}>
                                <div style={{flexDirection:"row", flex:1, alignItems:"center", justifyContent:"center"}}>
                                <Typography align="center" variant="body1">EWL <small style={{fontSize:10}}>g/h</small></Typography>
                                </div>
                            </ListItem>
                        </Grid>
                        <Grid item md={3} sm={3} xs={3}>
                            <ListItem style={{borderColor:"#000000", borderStyle:"solid", 
                            borderWidth:1, alignItems:"center", justifyContent:"center"}}>
                                 <div style={{flexDirection:"row", flex:1, alignItems:"center", justifyContent:"center"}}>
                                <Typography align="center" variant="body1">RMR <small style={{fontSize:10}}>W</small></Typography>
                                </div>
                            </ListItem>
                        </Grid>
                        </Grid>
                    <Grid container direction="row" alignItems="center">
                        <Grid item md={3} sm={3} xs={3}>
                        <ListItem style={{borderColor:"#000000", borderStyle:"solid", 
                            borderWidth:1, alignItems:"center", justifyContent:"center"}}>
                            <Typography align="center" variant="h6">SVR</Typography>
                        </ListItem>
                        </Grid>
                        <Grid item md={3} sm={3} xs={3}>
                            <Tooltip placement="top" title={<Typography variant="body2">R: 
                            {getPerformance(code, "svr", "temp").testing.toFixed(2)}</Typography>}>
                            <ListItem style={{borderColor:"#000000", flexDirection:"column", borderStyle:"solid", borderWidth:1, 
                            alignItems:"center", justifyContent:"center"}}>
                                <div style={{flexDirection:"row", flex:1, alignItems:"center", justifyContent:"center"}}>
                                <Typography align="center" style={{fontFamily:"Space Grotesk"}} variant="h6">
                                    {results.svr ? results.svr.tb : 0.00} 
                                </Typography>
                               
                                </div>
                            </ListItem>
                            </Tooltip>
                        </Grid>
                        <Grid item md={3} sm={3} xs={3}>
                        <Tooltip placement='top' title={<Typography variant="body2">R: 
                                {getPerformance(code, "svr", "ewl").testing.toFixed(2)}</Typography>}>
                            <ListItem style={{borderColor:"#000000", flexDirection:"column", borderStyle:"solid", borderWidth:1,
                             alignItems:"center", justifyContent:"center"}}>
                                <div style={{alignItems:"center", justifyContent:"center"}}>
                                <Typography align="center" style={{fontFamily:"Space Grotesk"}} variant="h6">
                                    {results.svr ? results.svr.ewl : 0.00} 
                                </Typography>
                                </div>
                            </ListItem>
                            </Tooltip>
                        </Grid>
                        <Grid item md={3} sm={3} xs={3}>
                            
                            <ListItem style={{borderColor:"#000000", borderStyle:"solid", 
                            borderWidth:1, alignItems:"center", justifyContent:"center"}}>
                                 <Tooltip placement='top' title={<Typography variant="body2">R: 
                                {getPerformance(code, "svr", "rmr").testing ? 
                                getPerformance(code, "svr", "rmr").testing.toFixed(2) : "-"}</Typography>}>
                                <div style={{alignItems:"center", justifyContent:"center"}}>
                                <Typography align="center" style={{fontFamily:"Space Grotesk"}} variant="h6">
                                 {results.svr ? results.svr.rmr : 0.00} 
                                </Typography>
                                </div>
                                </Tooltip>
                            </ListItem>
                        </Grid>
                        </Grid>
                        <Grid container direction="row">
                        <Grid item md={3} sm={3} xs={3}>
                        <ListItem style={{borderColor:"#000000", borderStyle:"solid", 
                            borderWidth:1, alignItems:"center", justifyContent:"center"}}>
                            <Typography align="center" variant="h6">GPR</Typography>
                        </ListItem>
                        </Grid>
                        <Grid item md={3} sm={3} xs={3}>
                            <ListItem style={{borderColor:"#000000", borderStyle:"solid", 
                            borderWidth:1, alignItems:"center", justifyContent:"center"}}>
                                <Tooltip placement='top' title={<Typography variant="body2">R: 
                                {getPerformance(code, "gpr", "temp").testing.toFixed(2)}</Typography>}>
                                <div style={{alignItems:"center", justifyContent:"center"}}>
                                <Typography align="center" style={{fontFamily:"Space Grotesk"}} variant="h6">
                                    {results.gpr ? results.gpr.tb : 0.00}  
                                </Typography>
                                </div>
                                </Tooltip>
                            </ListItem>
                        </Grid>
                        <Grid item md={3} sm={3} xs={3}>
                            <ListItem style={{borderColor:"#000000", borderStyle:"solid", 
                            borderWidth:1, alignItems:"center", justifyContent:"center"}}>
                                 <Tooltip placement='top' title={<Typography variant="body2">R: 
                                {getPerformance(code, "gpr", "ewl").testing.toFixed(2)}</Typography>}>
                                <div style={{alignItems:"center", justifyContent:"center"}}>
                                <Typography align="center" style={{fontFamily:"Space Grotesk"}} variant="h6">
                                    {results.gpr ? results.gpr.ewl : 0.00} 
                                </Typography>
                                </div>
                                </Tooltip>
                               
                            </ListItem>
                        </Grid>
                        <Grid item md={3} sm={3} xs={3}>
                            <ListItem style={{borderColor:"#000000", borderStyle:"solid", borderWidth:1,
                             alignItems:"center", justifyContent:"center"}}>
                                <Tooltip placement='top' title={<Typography variant="body2">R: 
                                {getPerformance(code, "gpr", "rmr").testing ? 
                                getPerformance(code, "gpr", "rmr").testing.toFixed(2): "-"}</Typography>}>
                                <div style={{alignItems:"center", justifyContent:"center"}}>
                                <Typography align="center" style={{fontFamily:"Space Grotesk"}} variant="h6">
                                    {results.gpr ? results.gpr.rmr : 0.00} 
                                </Typography>
                                </div>
                                </Tooltip>
                            </ListItem>
                        </Grid>
                        </Grid>
                        <Grid container direction="row">
                        <Grid item md={3} sm={3} xs={3}>
                        <ListItem style={{borderColor:"#000000", borderStyle:"solid", 
                            borderWidth:1, alignItems:"center", justifyContent:"center"}}>
                            <Typography align="center" variant="h6">ANN</Typography>
                        </ListItem>
                        </Grid>
                        <Grid item md={3} sm={3} xs={3}>
                            <ListItem style={{borderColor:"#000000", borderStyle:"solid", 
                            borderWidth:1, alignItems:"center", justifyContent:"center"}}>
                                 <Tooltip placement='top' title={<Typography variant="body2">R: 
                                {getPerformance(code, "ann", "temp").testing.toFixed(2)}</Typography>}>
                                <div style={{alignItems:"center", justifyContent:"center"}}>
                                <Typography align="center" style={{fontFamily:"Space Grotesk"}} variant="h6">
                                    {results.ann ? results.ann.tb : 0.00} 
                                </Typography>
                                </div>
                                </Tooltip>
                               
                            </ListItem>
                        </Grid>
                        <Grid item md={3} sm={3} xs={3}>
                            <ListItem style={{borderColor:"#000000", borderStyle:"solid", borderWidth:1,
                             alignItems:"center", justifyContent:"center"}}>
                                <Tooltip placement='top' title={<Typography variant="body2">R: 
                                {getPerformance(code, "ann", "ewl").testing.toFixed(2)}</Typography>}>
                                <div style={{alignItems:"center", justifyContent:"center"}}>
                                <Typography align="center" style={{fontFamily:"Space Grotesk"}} variant="h6">
                                    {results.ann ? results.ann.ewl : 0.00} 
                                </Typography>
                                </div>
                                </Tooltip>
                            </ListItem>
                        </Grid>
                        <Grid item md={3} sm={3} xs={3}>
                            <ListItem style={{borderColor:"#000000", borderStyle:"solid", 
                            borderWidth:1, alignItems:"center", justifyContent:"center"}}>
                                <Tooltip placement='top' title={<Typography variant="body2">R: 
                                {getPerformance(code, "ann", "rmr").testing ? 
                                getPerformance(code, "ann", "rmr").testing.toFixed(2) : "-"}</Typography>}>
                                <div style={{alignItems:"center", justifyContent:"center"}}>
                                <Typography align="center" style={{fontFamily:"Space Grotesk"}} variant="h6">
                                {results.ann ? results.ann.rmr : 0.00} 
                                </Typography>
                                </div>
                                </Tooltip>
                            </ListItem>
                        </Grid>
                        </Grid>
                        <Grid container direction="row">
                        <Grid item md={3} sm={3} xs={3}>
                        <ListItem style={{borderColor:"#000000", borderStyle:"solid", 
                            borderWidth:1, alignItems:"center", justifyContent:"center"}}>
                            <Typography align="center" variant="h6">ENS</Typography>
                        </ListItem>
                        </Grid>
                        <Grid item md={3} sm={3} xs={3}>
                            <ListItem style={{borderColor:"#000000", borderStyle:"solid", 
                            borderWidth:1, alignItems:"center", justifyContent:"center"}}>
                                 <Tooltip placement='top' title={<Typography variant="body2">R: 
                                {getPerformance(code, "ensemble", "temp").testing.toFixed(2)}</Typography>}>
                                <div style={{alignItems:"center", justifyContent:"center"}}>
                                <Typography align="center" style={{fontFamily:"Space Grotesk"}} variant="h6">
                                {results.ensemble ? results.ensemble.tb : 0.00} 
                                </Typography>
                                </div>
                                </Tooltip>
                               
                            </ListItem>
                        </Grid>
                        <Grid item md={3} sm={3} xs={3}>
                            <ListItem style={{borderColor:"#000000", borderStyle:"solid",
                             borderWidth:1, alignItems:"center", justifyContent:"center"}}>
                                <Tooltip placement='top' title={<Typography variant="body2">R: 
                                {getPerformance(code, "ensemble", "ewl").testing.toFixed(2)}</Typography>}>                                
                                <div style={{alignItems:"center", justifyContent:"center"}}>
                                <Typography align="center" style={{fontFamily:"Space Grotesk"}} variant="h6">
                                {results.ensemble ? results.ensemble.ewl : 0.00} 
                                </Typography>
                                </div>
                                </Tooltip>
                            </ListItem>
                        </Grid>
                        <Grid item md={3} sm={3} xs={3}>
                            <ListItem style={{borderColor:"#000000", borderStyle:"solid", 
                            borderWidth:1, alignItems:"center", justifyContent:"center"}}>
                                 <Tooltip placement='top' title={<Typography variant="body2">R: 
                                {getPerformance(code, "ensemble", "rmr").testing ? 
                                getPerformance(code, "ensemble", "rmr").testing.toFixed(2) : "-"}</Typography>}>  
                                <div style={{alignItems:"center", justifyContent:"center"}}>
                                <Typography align="center" style={{fontFamily:"Space Grotesk"}} variant="h6">
                                {results.ensemble ? results.ensemble.rmr : 0.00} 
                                </Typography>
                                </div>
                                </Tooltip>
                            </ListItem>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
       
            
        </Grid>
        </Grid>   
        </div>
    )
}


function MassSlider(props){
    const [mass, setMass] = useState(props.min);

    function valuetext(value) {
        return `${value}°C`;
    }

    const handleChange = (event, newValue) => {
        setMass(newValue);
        props.onChange(newValue)
    };
    const marks = [
        {
          value: props.min,
          label: props.min + 'g',
        },
        {
          value: ((props.max+props.min)/2).toFixed(1),
          label: ((props.max+props.min)/2).toFixed(1) + 'g',
        },
        {
          value: props.max,
          label: props.max + 'g',
        },
      ];
    return(
        <div>
            <Grid container direction="row" justifyContent="space-between">
                    <Grid item>
                    <Typography variant="h6"><b>Body Mass</b></Typography>
                    </Grid>
                    <Grid item>
                    <Typography variant="h6" style={{fontFamily:"Space Grotesk"}} >{mass} g</Typography>
                    </Grid>
                    
                    </Grid>
                   
                    <Slider
                        aria-label="Custom marks"
                        defaultValue={props.min}
                        onChange={handleChange}
                        max={props.max}
                        min={props.min}
                        disabled={!props.enable}
                        step={0.1}
                        valueLabelDisplay="auto"
                        marks={marks}
                        getAriaValueText={valuetext}
                    />
        </div>
    )
}

function TempSlider(props){
    const [temp, setTemp] = useState(20);

    const handleChange = (event, newValue) => {
        setTemp(newValue);
        props.onChange(newValue);
    };

    function valuetext(value) {
        return `${value}°C`;
    }
    const marks = [
        {
          value: 0,
          label: '0°C',
        },
        {
          value: 20,
          label: '20°C',
        },
        {
          value: 40,
          label: '40°C',
        },
        {
          value: 50,
          label: '50°C',
        },
      ];
    return(
        <div>
            <Grid container direction="row" justifyContent="space-between">
                    <Grid item>
                    <Typography variant="h6"><b>Ambient Temperature</b></Typography>
                    </Grid>
                    <Grid item>
                    <Typography variant="h6" style={{fontFamily:"Space Grotesk"}} >{temp} &#xb0;C</Typography>
                    </Grid>
                    </Grid>
                    <Slider
                        aria-label="Custom marks"
                        defaultValue={temp}
                        onChange={handleChange}
                        max={50}
                        disabled={!props.enable}
                        getAriaValueText={valuetext}
                        step={0.1}
                        valueLabelDisplay="auto"
                        marks={marks}
                    />
        </div>
    )
}